import { CallToActionOneData } from "@/data";
import Link from "@/components/link";
import React from "react";

const CtaOne = () => {
  const { title, button } = CallToActionOneData;
  return (
    <section className="call_to_action">
      <div className="container">
        <div className="row">
          <div className="text-background">
            <div className="">
            <div class="subtitle title-base">
               Jusqu'au 31 décembre
            </div>
            <div class="title title-big">
                -20%
            </div>
            <div class="text text-base ">
                <p>Sur les fenêtres PVC !</p>
            </div>
            
            </div>
            <div className="col-md-10 col-sm-4 col-xs-12 text-end cta_responsive_left">
              <Link href={button.url} className="btn-gray">
                {button.label}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaOne;
