import React , { useState, useRef ,useEffect}  from "react";
import emailjs from '@emailjs/browser';
const ContactOne = () => {
     //ajouter contact      
     const [successful, setSuccessful] = useState(false);
     const [message, setMessage] = useState("");
     const form = useRef();
    
     const sendEmail = (e) => {
       e.preventDefault();
   
       emailjs.sendForm('service_2zh3ili', 'template_xs42kzv', form.current, 'LKQFjMtJuBP8Y1m9z')
         .then((result) => {
           console.log(result.text);
             setMessage("vos informations on bien été envoyer")
             setSuccessful(true);
             console.log("votre mail a bien ete envoyer ")
         }, (error) => {
             console.log(error.text);
             setMessage("erreur de connexion")
             setSuccessful(false);
         });
     };
  return (
    <section className="contact_form_area">
      <div className="contact_form_width">
        <h1 className="contact_map_title text-center">
        Nous sommes ici pour satisfaire vos besoins.
        </h1>
        <div id="map">
        <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=184%20avenue%20de%20choisy%20paris%20+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a></iframe>
        </div>
      </div>
      <div className="contact_form_width contact-right">
        <div className="hero-title-with-shape">
          <h4 className="heading_with_border">Obtenir un devis gratuit</h4>
          <h1>Pour demander un appel de service, veuillez remplir le formulaire ci-dessous</h1>
        </div>
        <div className="contact-right contact-right-style-2 responsive_mt">
            <form ref={form} onSubmit={sendEmail}>
              {!successful && (
                <div className="">
                  <input
                  className="half_width input_m_right"
                  type="text"
                  name="nom"
                  placeholder="votre nom"
                />
                <input
                  className="half_width"
                  type="text"
                  name="prenom"
                  placeholder="prenom"
                />
                <input 
                  type="tel" 
                  name="telephone"
                 
                  placeholder="votre telephone" />
              
                <button className="btn-yellow" value="submit">
                  VALIDER
                </button>
                </div>
            
                 )}
                  {message && (
                              <div className="form-group">
                                <div
                                  className={
                                    successful
                                      ? "alert alert-success"
                                      : "alert alert-danger"
                                  }
                                  role="alert"
                                >
                                  {message}
                                </div>
                              </div>
                  )}
                
              </form>
             
            </div>
             
      </div>
    </section>
  );
};

export default ContactOne;
